import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class StorageService {

  constructor() { }

  public setBearer(bearer) {
    this._setValue('bearer', bearer);
  }

  public getBearer() {
    return this._getValue('bearer');
  }

  public setAzureToken(token) {
    this._setValue('azure_token', token);
  }

  public getAzureToken() {
    return this._getValue('azure_token');
  }

  public setAzureAppId(appid) {
    this._setValue('azure_app_id', appid);
  }

  public getAzureAppId() {
    return this._getValue('azure_app_id');
  }

  public setClientBearer(clientBearer) {
    this._setValue('bearer_client', clientBearer);
  }

  public getClientBearer() {
    this._getValue('clientBearer');
  }

  public setRefreshToken(refreshToken) {
    this._setValue('refresh_token', refreshToken);
  }

  public getRefreshToken() {
    return this._getValue('refresh_token');
  }

  public setTokenExpiration(time) {
    this._setValue('token_expiration', time);
  }

  public getTokenExpiration(): any {
    return this._getValue('token_expiration');
  }

  public setCurrentOrganisation(organisationId) {
    this._setValue('organisation_id', organisationId);
  }

  public getCurrentOrganisation() {
    return this._getValue('organisation_id');
  }

  public storeOAuthResponse(response) {
    this.setBearer(response.access_token);
    this.setRefreshToken(response.refresh_token);
    this.setTokenExpiration( Math.floor(Number(Date.now()) / 1000) + Number(response.expires_in));
  }

  public cleanOAuth() {
    this._forget('bearer');
    this._forget('refresh_token');
    this._forget('token_expiration');
    this._forget('organisation_id');
    this._forget('azure_token');
    this._forget('azure_app_id');
  }

  public setLanguage(lang) {

    if (lang !== 'fr' && lang !== 'nl' && lang !== 'en' && lang !== 'es') {
      lang = 'en';
    }

    this._setValue('language', lang);
  }

  public getLanguage() {
    let lang = this._getValue('language');

    if (lang !== 'fr' && lang !== 'nl' && lang !== 'en' && lang !== 'es') {
        lang = 'en';
    }

    return lang;
  }

  public setLocalId(lang) {
    this._setValue('localId', lang);
  }

  public getLocalId() {
    return this._getValue('localId');
  }

  public setThrottleTime(time: number) {
    this._setValue('throttle', time);
  }

  public isThrottleTimePassed() {

    if (this._getValue('throttle') === null) {
        this.setThrottleTime(Date.now() - 3000);
    }

    const isThrottleTimePassed = parseInt(this._getValue('throttle')) < Date.now();

    this.setThrottleTime(Date.now() + 3000);

    return isThrottleTimePassed;
  }

  private _getValue(key) {
    return window.localStorage.getItem(key);
  }

  private _setValue(key, value) {
    window.localStorage.setItem(key, value);
  }

  private _forget(key) {
    window.localStorage.removeItem(key);
  }
}
